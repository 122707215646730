import { FOOTER_VISIBLE, useLayoutDispatcher } from "context/layoutContext"
import { useEffect } from "react"

//
// Custom hook to enable/disable the visibilty of the footer
//
export const useFooter = (isVisible) => {
  const dispatch = useLayoutDispatcher()
  useEffect(() => {
    dispatch({
      type: FOOTER_VISIBLE,
      payload: {
        footerVisible: isVisible,
      },
    })

    // Hide footer on component unmount to prevent flicker
    return function cleanup() {
      dispatch({
        type: FOOTER_VISIBLE,
        payload: {
          footerVisible: false,
        },
      })
    }
  }, [isVisible, dispatch])
}
